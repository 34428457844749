import React, { Fragment, useCallback, useEffect } from 'react'

import { useDomainAPI } from '@/api/hook'
import { useDomainList, useDomainLoading } from '@/module/domain/hook'
import { CreateDomainInfoDto, DomainInfo } from '@/module/domain/types'
import { useSelected } from '@/module/home/hook'
import DomainList from './DomainList'
import DomainListHeader from './DomainListHeader'
import DomainAddModal, { ADD_DOMAIN_MODAL } from './DomainAddModal'
import { FallbackProps } from 'react-error-boundary'
import { useAuth } from '@/module/auth/hook'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { initialUser } from '@/module/auth/context'
import { Navigate, useNavigate } from 'react-router-dom'

function DomainManagement() {
  const navigate = useNavigate()
  const { productType } = useSelected()
  const { query, postMutation } = useDomainAPI()
  const { setIsSaveLoading } = useDomainLoading()
  const { domain, setSelectedDomain, setDomainList, setDomainCount, setDomainUserCount } =
    useDomainList()

  useEffect(() => {
    if (query.data != null) {
      setDomainList(query.data)
    }
  }, [query.data])

  useEffect(() => {
    setDomainCount(domain.items.length)
    setDomainUserCount(domain.items.reduce((sum, { userCount }) => sum + (userCount ?? 0), 0))
  }, [domain.items])

  useEffect(() => {
    if (postMutation.isLoading) {
      setIsSaveLoading(true)
    }
  }, [postMutation.isLoading])

  const checkDuplicateDomain = useCallback(
    (currDomain: string) => {
      for (let i = 0; i < domain.items.length; i++) {
        if (domain.items[i].domain === currDomain) {
          return true
        }
      }
      return false
    },
    [domain.items],
  )

  const handleAddModal = useCallback((domain: DomainInfo) => {
    const dto: CreateDomainInfoDto = {
      domain: domain.domain,
      maxLicense: domain.maxLicense,
      description: domain.description,
      name: domain.name,
      locale: domain.locale,
      address: domain.address,
      adminCount: domain.adminCount,
      type: domain.type,
      createTime: Math.floor(new Date(Date.now()).getTime() / 1000),
      expireTime: Math.floor(new Date(domain.expireTime).getTime() / 1000),
    }

    postMutation.mutate(dto, {
      onSuccess: () => closeAddDomainModal(),
      onError: (err) => alert((err as Error).message),
      onSettled: () => setIsSaveLoading(false),
    })
  }, [])

  const openAddDomainModal = () => {
    ;(document.getElementById(ADD_DOMAIN_MODAL) as HTMLDialogElement)?.showModal()
  }
  const closeAddDomainModal = () => {
    ;(document.getElementById(ADD_DOMAIN_MODAL) as HTMLDialogElement)?.close()
  }

  return (
    <Fragment>
      <DomainListHeader
        domainCount={domain.count}
        domainUserCount={domain.userCount}
        onAddDomainClick={() => {
          setSelectedDomain(null)
          openAddDomainModal()
        }}
      />
      <DomainList
        list={domain.items}
        onClickListItem={(item) => {
          setSelectedDomain(item)
        }}
        onClickEditDomainPage={(item) => {
          navigate(`/${productType}/${encodeURIComponent(item.domain)}`)
        }}
      />
      <DomainAddModal
        onAdd={(domain) => {
          if (checkDuplicateDomain(domain.domain)) {
            alert('동일한 도메인이 존재합니다. ')
            return
          }

          handleAddModal(domain)
        }}
        onCancel={() => closeAddDomainModal()}
      />
    </Fragment>
  )
}

export function ErrorFallback({ error }: FallbackProps) {
  const { handleSetUser } = useAuth()
  const err = error as AxiosError

  if (err.response?.status === StatusCodes.UNAUTHORIZED) {
    handleSetUser(initialUser)
    alert(`${err.response.status}, ${err.response.statusText}, ${err.message}, ${err.name}`)
    return <Navigate to='/' replace />
  }

  return (
    <div className='flex flex-col w-full h-full overflow-y-auto'>
      <p>{`${err.name}\n${err.message}`}</p>
    </div>
  )
}

export default DomainManagement
